import React from 'react'
import Slick from 'react-slick'

import * as S from './style'

export const Slider: React.FC<{
  children: React.ReactNode
}> = props => {
  const { children } = props
  return (
    <S.Content>
      <Slick
        dots
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        adaptiveHeight
        arrows={false}
        customPaging={(): JSX.Element => <S.Dot />}
        autoplay
        autoplaySpeed={10000}
      >
        {children}
      </Slick>
    </S.Content>
  )
}
