import styled from 'styled-components'
import Dimensions from 'spartacus/styles/dimensions'
import Colors from 'spartacus/styles/colors'

export const Container = styled.div`
  background: ${Colors.UI.White};
  padding: 4vh ${Dimensions.nColumns(2)};

  @media ${Dimensions.md} {
    padding: 8vh ${Dimensions.nColumns(2)};
  }
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media ${Dimensions.md} {
    align-items: center;
  }
`

export const TextContainer = styled.div`
  h4 {
    font-weight: 600;
    font-size: 14px;
    color: ${Colors.Brand.Primary1};
    letter-spacing: 0.88px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  @media ${Dimensions.md} {
    flex: ${5 / 12};
  }
`

export const SethLockup = styled.div`
  display: grid;
  text-align: center;
  margin-top: 60px;
  grid-template-areas:
    'seth-image'
    'seth-lockup-content-attribution'
    'seth-lockup-content-body';

  @media ${Dimensions.md} {
    display: flex;
    text-align: left;
    align-items: center;
  }
`

export const SethImageContainer = styled.div`
  width: 100%;
  height: auto;
  margin-right: 24px;
  grid-area: seth-image;

  @media ${Dimensions.md} {
    width: 64px;
    height: 64px;
  }

  picture {
    max-width: 64px;
    display: inline-block;
    margin: 0 0 24px;
  }

  img {
    width: 100%;
    border-radius: 100%;
  }
`

export const SethLockupContent = styled.div`
  span {
    display: block;
    grid-area: seth-lockup-content-body;

    &:first-of-type {
      grid-area: seth-lockup-content-attribution;
      margin: 0 0 8px;

      &::before {
        content: '— ';
        white-space: pre;
        display: inline-block;

        @media ${Dimensions.md} {
          content: '';
        }
      }
    }
  }
`

export const IllustrationContainer = styled.div`
  order: -1;
  max-width: 234px;
  margin: 0 auto 24px;

  img {
    width: 100%;
    margin: 0 auto;
    display: block;
    max-width: 400px;
  }

  @media ${Dimensions.md} {
    margin: 0 0 32px auto;
    order: 0;
    max-width: unset;
    flex: ${5 / 12};

    img {
      max-width: 736px;
      display: block;
    }
  }
`
