import * as React from 'react'
import Image from 'spartacus/components/Image'
import { Slider } from 'spartacus/components/Slider'
import useBreakpoint from 'spartacus/hooks/useBreakpoint'
import Typography from 'spartacus/components/Typography'
import Link from 'next/link'
import Deciens from './assets/Deciens.png?resize'
import DeciensWebP from './assets/Deciens.png?webp'
import Slow from './assets/Slow.png?resize'
import SlowWebP from './assets/Slow.png?webp'
import ClocktowerTechnologyVentures from './assets/ClocktowerTechnologyVentures.png?resize'
import ClocktowerTechnologyVenturesWebP from './assets/ClocktowerTechnologyVentures.png?webp'
import M13 from './assets/M13.png?resize'
import M13WebP from './assets/M13.png?webp'

import * as S from './style'

const LandingInvestors = (): JSX.Element => {
  const bp = useBreakpoint('md')

  return (
    <S.Container>
      <S.Row>
        <S.Content>
          <Typography element="h2" md="TS5 - Strong" xs="TS4 - Strong">
            We’ve got backup.
          </Typography>
          <Typography element="p" md="TS3 - Regular">
            We’ve partnered with world-class investors and advisors who stand with our promise of
            protecting your privacy.
          </Typography>
        </S.Content>
        {!bp ? (
          <Slider>
            <S.Slide>
              <Image src={M13} webp={M13WebP} type="image/png" alt="m13" loading="lazy" />
            </S.Slide>
            <S.Slide>
              <Image src={Slow} webp={SlowWebP} type="image/png" alt="Slow web" loading="lazy" />
            </S.Slide>
            <S.Slide>
              <Image
                src={ClocktowerTechnologyVentures}
                webp={ClocktowerTechnologyVenturesWebP}
                type="image/png"
                alt="Clock Tower Technology Ventures"
                loading="lazy"
              />
            </S.Slide>
            <S.Slide>
              <Image
                src={Deciens}
                webp={DeciensWebP}
                type="image/png"
                alt="Deciens"
                loading="lazy"
              />
            </S.Slide>
          </Slider>
        ) : (
          <S.Logos>
            <S.Logo>
              <Image src={M13} webp={M13WebP} type="image/png" alt="m13" loading="lazy" />
            </S.Logo>
            <S.Logo>
              <Image src={Slow} webp={SlowWebP} type="image/png" alt="Slow web" loading="lazy" />
            </S.Logo>
            <S.Logo>
              <Image
                src={ClocktowerTechnologyVentures}
                webp={ClocktowerTechnologyVenturesWebP}
                type="image/png"
                alt="Clock Tower Technology Ventures"
                loading="lazy"
              />
            </S.Logo>
            <S.Logo>
              <Image
                src={Deciens}
                webp={DeciensWebP}
                type="image/png"
                alt="Deciens"
                loading="lazy"
              />
            </S.Logo>
          </S.Logos>
        )}
        <S.PromiseLink>
          <Link href="/our-promise" passHref>
            <Typography element="a" xs="TSB - Button" href="https://spartacus.com/our-promise/">
              Read Our Promise
            </Typography>
          </Link>
        </S.PromiseLink>
      </S.Row>
    </S.Container>
  )
}

export default LandingInvestors
