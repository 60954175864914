import * as React from 'react'
import Typography from 'spartacus/components/Typography'
import Image from 'spartacus/components/Image'
import WhyTrustUsImage from './assets/why-trust-us.png?resize'
import WhyTrustUsImageWebP from './assets/why-trust-us.png?webp'
import SethAvatarImage from './assets/seth-avatar.jpg?resize'
import SethAvatarImageWebP from './assets/seth-avatar.jpg?webp'

import * as S from './style'

const LandingWhyTrustUs = (): JSX.Element => (
  <S.Container>
    <S.Content>
      <S.TextContainer>
        <Typography element="h3" md="TS5 - Strong" xs="TS4 - Strong">
          Trust is built on transparency
        </Typography>
        <Typography element="p" md="TS2 - Regular">
          We believe this is not the internet we intended to build. We believe that privacy is a
          fundamental human value that is worth fighting for. Having worked in the Tech industry
          ourselves, we saw that there was a complete lack in privacy of consumer data which is why
          we take your data seriously and want to establish an understanding for how we can offer
          the most value to you in this new relationship.
        </Typography>
        <S.SethLockup>
          <S.SethImageContainer>
            <Image
              src={SethAvatarImage}
              webp={SethAvatarImageWebP}
              type="image/png"
              alt="Seth"
              loading="lazy"
            />
          </S.SethImageContainer>
          <S.SethLockupContent>
            <Typography md="TS2 - Strong" xs="TS2 - Strong">
              Seth Goldstein
            </Typography>
            <Typography md="TS2 - Regular">Founder, father, &amp; entrepreneur</Typography>
          </S.SethLockupContent>
        </S.SethLockup>
      </S.TextContainer>
      <S.IllustrationContainer>
        <Image
          src={WhyTrustUsImage}
          webp={WhyTrustUsImageWebP}
          type="image/png"
          alt="Trust is built on transparency"
          loading="lazy"
        />
      </S.IllustrationContainer>
    </S.Content>
  </S.Container>
)

export default LandingWhyTrustUs
