import * as React from 'react'
import EmailScanNowInput from 'spartacus/components/EmailScanNowInput'
import Image from 'spartacus/components/Image'
import ScrollIndicator from 'spartacus/components/ScrollIndicator'
import Typography from 'spartacus/components/Typography'
import Banner from 'spartacus/components/Banner'
import useBreakpoint from 'spartacus/hooks/useBreakpoint'
import Colors from 'spartacus/styles/colors'
import HeroImage from './assets/hero.jpg?resize'
import HeroImageWebP from './assets/hero.jpg?webp'
import HeroMobileImage from './assets/hero-mobile.jpg?resize'
import HeroMobileImageWebP from './assets/hero-mobile.jpg?webp'
import * as S from './style'

const LandingHero = (): JSX.Element => {
  const isAtLeastMiddleSize = useBreakpoint('md')

  return (
    <S.Container>
      <S.HeroImageContainer>
        <Image src={HeroImage} webp={HeroImageWebP} type="image/jpeg" alt="Privacy, simplified." />
        <Image
          src={HeroMobileImage}
          webp={HeroMobileImageWebP}
          type="image/jpeg"
          alt="Privacy, simplified."
        />
      </S.HeroImageContainer>
      {/* TODO: create an admin flag where we can turn on and off without needing to code */}
      {false && (
        <S.BannerContainer>
          <Banner
            title={
              isAtLeastMiddleSize ? 'SAVE 15% FOR CYBER MONDAY. USE CODE' : 'SAVE 15%. USE CODE'
            }
            promo="CYBER15."
          />
        </S.BannerContainer>
      )}
      <S.InnerContainer>
        <S.Content>
          <Typography element="h1" md="TS7 - Strong" xs="TS6 - Strong" color={Colors.UI.White}>
            Privacy, simplified.
          </Typography>
          <Typography element="p" md="TS3 - Regular" xs="TS2 - Regular" color={Colors.UI.White}>
            Scan your email to see your privacy score and start deleting your exposed data for free.
          </Typography>
          <EmailScanNowInput
            type="public-dashboard"
            id="landing-hero"
            analyticsLocation="top"
            lightOnDark
          />
        </S.Content>
        <S.ScrollIndicatorContainer>
          <ScrollIndicator title="What we do" lightOnDark />
        </S.ScrollIndicatorContainer>
      </S.InnerContainer>
    </S.Container>
  )
}

export default LandingHero
