import styled, { keyframes } from 'styled-components'
import Colors from 'spartacus/styles/colors'

const bounce = keyframes`
  from,
  10%,
  20%,
  to {
    animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translate3d(0, 0, 0);
  }

  10% {
    animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translate3d(0, 4px, 0);
  }

  20% {
    animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translate3d(0, 0, 0);
  }
`

export const Container = styled.div<{ 'data-light-on-dark': boolean }>`
  text-align: center;
  margin-top: 32px;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.88px;
  text-transform: uppercase;
  color: ${(props): string =>
    props['data-light-on-dark'] ? Colors.Brand.Primary3 : Colors.Brand.Primary1};
  animation-duration: 4s;
  animation-fill-mode: both;
  animation-name: ${bounce};
  animation-iteration-count: infinite;
  transform-origin: center bottom;

  span {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: ${(props): string =>
      props['data-light-on-dark'] ? Colors.Brand.Primary3 : Colors.Brand.Primary1};
  }
`
