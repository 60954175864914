import * as React from 'react'
import Image from 'spartacus/components/Image'
import Typography from 'spartacus/components/Typography'
import Colors from 'spartacus/styles/colors'
import Illustration from './assets/frame-1.jpg?resize'
import IllustrationWebP from './assets/frame-1.jpg?webp'
import Illustration2 from './assets/frame-2.jpg?resize'
import IllustrationWebP2 from './assets/frame-2.jpg?webp'
import Illustration3 from './assets/frame-3.jpg?resize'
import IllustrationWebP3 from './assets/frame-3.jpg?webp'
import * as S from './style'

const Instagram = (): JSX.Element => (
  <>
    <S.Content>
      <Image src={Illustration} webp={IllustrationWebP} type="image/jpeg" alt="" />
      <Image src={Illustration2} webp={IllustrationWebP2} type="image/jpeg" alt="" />
      <Image src={Illustration3} webp={IllustrationWebP3} type="image/jpeg" alt="" />
    </S.Content>
    <S.Footer>
      <Typography md="TS3 - Strong" xs="TS2 - Strong" color={Colors.Brand.Primary6}>
        <a href="https://instagram.com/getspartacus">@getspartacus</a>
      </Typography>
    </S.Footer>
  </>
)

export default Instagram
