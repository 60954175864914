import * as React from 'react'
import EmailScanNowInput from 'spartacus/components/EmailScanNowInput'
import PrivacyScoreChart from 'spartacus/components/PrivacyScore/PrivacyScoreChart'
import Typography from 'spartacus/components/Typography'
import useBreakpoint from 'spartacus/hooks/useBreakpoint'
import Colors from 'spartacus/styles/colors'
import * as S from './style'

const GetStarted = (): JSX.Element => {
  const bp = useBreakpoint('md')
  const FormWrap = bp ? S.Form : React.Fragment

  return (
    <S.Container>
      <S.Content>
        <FormWrap>
          <Typography element="h2" md="TS5 - Strong" xs="TS4 - Strong" color={Colors.UI.White}>
            Get started for free
          </Typography>
          <S.Description>
            <Typography element="p" md="TS3 - Regular" color={Colors.UI.White}>
              Boost your privacy with free data broker deletion.
            </Typography>
          </S.Description>
          <EmailScanNowInput
            type="public-dashboard"
            id="landing-get-started"
            analyticsLocation="middle"
            buttonLabel="Get Started"
            lightOnDark
          />
        </FormWrap>
        <S.Chart>
          <PrivacyScoreChart
            privacyScore={20}
            svgID="dashboard-get-started"
            textColor={Colors.UI.White}
          />
        </S.Chart>
      </S.Content>
    </S.Container>
  )
}

export default GetStarted
