import * as React from 'react'
import Image from 'spartacus/components/Image'
import { Slider } from 'spartacus/components/Slider'
import Typography from 'spartacus/components/Typography'
import Slide1Image from './assets/slide-1.png?resize'
import Slide1ImageWebP from './assets/slide-1.png?webp'
import Slide2Image from './assets/slide-2.png?resize'
import Slide2ImageWebP from './assets/slide-2.png?webp'
import Slide3Image from './assets/slide-3.png?resize'
import Slide3ImageWebP from './assets/slide-3.png?webp'
import * as S from './style'

const LandingCarousel = (): JSX.Element => (
  <S.Container>
    <Slider>
      <S.Slide>
        <S.SlideContent>
          <S.SlideImage>
            <Image
              src={Slide1Image}
              webp={Slide1ImageWebP}
              type="image/png"
              alt="1 in 5 people will lose money in imposter scams"
              loading="lazy"
            />
          </S.SlideImage>
          <S.SlideText>
            <Typography element="h3" md="TS5 - Strong" xs="TS4 - Strong">
              1 in 5 people will lose money in imposter scams.
            </Typography>
            <Typography element="p" md="TS2 - Regular">
              The more personal data you have out there, the easier it is for bad guys to open up
              credit lines and bank accounts. They can even file taxes in your name. We offer data
              broker deletion services so your information is deleted off the web.
            </Typography>
          </S.SlideText>
        </S.SlideContent>
      </S.Slide>

      <S.Slide>
        <S.SlideContent>
          <S.SlideImage>
            <Image
              src={Slide2Image}
              webp={Slide2ImageWebP}
              type="image/png"
              alt="End suspicious logins… for good"
              loading="lazy"
            />
          </S.SlideImage>
          <S.SlideText>
            <Typography element="h3" md="TS5 - Strong" xs="TS4 - Strong">
              End suspicious logins… for good.
            </Typography>
            <Typography element="p" md="TS2 - Regular">
              2 in 5 people have received a notice that their personal information had been
              compromised. Sound like you? See what passwords have been exposed in breaches and
              leaks with our service and learn how to protect them.
            </Typography>
          </S.SlideText>
        </S.SlideContent>
      </S.Slide>

      <S.Slide>
        <S.SlideContent>
          <S.SlideImage>
            <Image
              src={Slide3Image}
              webp={Slide3ImageWebP}
              type="image/png"
              alt="Identity theft is costly and tedious"
              loading="lazy"
            />
          </S.SlideImage>
          <S.SlideText>
            <Typography element="h3" md="TS5 - Strong" xs="TS4 - Strong">
              Identity theft is costly and tedious.
            </Typography>
            <Typography element="p" md="TS2 - Regular">
              Victims of identity theft spend on average 330 hours repairing the damage. Our
              services help prevent identity theft so you can spend those hours doing the things you
              love. Take back control over your life and privacy!
            </Typography>
          </S.SlideText>
        </S.SlideContent>
      </S.Slide>
    </Slider>
  </S.Container>
)

export default LandingCarousel
