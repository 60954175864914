import styled from 'styled-components'
import dimensions from 'spartacus/styles/dimensions'

export const Container = styled.div`
  padding: 0 ${dimensions.nColumns(2)} 8vh ${dimensions.nColumns(2)};

  @media ${dimensions.lg} {
    padding: 4vh ${dimensions.nColumns(2)} 12vh;
    display: grid;
    grid-template-areas:
      'copy . list'
      'cta . list';
    grid-template-columns: ${dimensions.nColumns(9)} ${dimensions.nColumns(4)} ${dimensions.nColumns(
        8,
      )};
  }
`

export const CopyContainer = styled.div`
  grid-area: copy;
  align-self: end;

  text-align: center;
  margin-bottom: 4vh;

  @media ${dimensions.lg} {
    text-align: left;
    margin-bottom: 0;
  }
`

export const List = styled.ul`
  grid-area: list;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 6vh;

  li {
    display: grid;
    grid-template-areas:
      'icon'
      'title'
      'description';
    grid-row-gap: 1vh;
    margin-bottom: 6vh;

    h3 {
      grid-area: title;
      margin: 0;
    }

    p {
      grid-area: description;
      margin: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media ${dimensions.lg} {
    margin-bottom: 0;

    li {
      grid-template-areas:
        'icon title'
        'icon description';
      grid-template-columns: 32px auto;
      grid-column-gap: ${dimensions.nColumns(1)};
    }
  }
`

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;

  img {
    width: 100%;
    height: 100%;
  }

  @media ${dimensions.lg} {
    width: 32px;
    height: 32px;
    position: relative;
    top: 4px;
  }
`

export const CTAContainer = styled.div`
  grid-area: cta;
  align-self: start;
  text-align: center;

  @media ${dimensions.lg} {
    text-align: left;
  }
`
