import React, { memo } from 'react'
import Typography from 'spartacus/components/Typography'
import * as S from './style'

export interface Props {
  title: string
  lightOnDark?: boolean
}

const ScrollIndicator = ({ title, lightOnDark = false }: Props): JSX.Element => (
  <S.Container data-light-on-dark={lightOnDark}>
    <Typography kind="TSC - Callout">{title}</Typography>
    <svg width="10" height="18">
      <path
        fill="currentColor"
        d="M 9.71,12.29 C 10.1,12.68 10.1,13.32 9.71,13.71 L 5.71,17.71 5.71,17.71 C 5.61,17.8 5.5,17.88 5.38,17.92 5.26,17.97 5.14,18 5,18 4.86,18 4.74,17.97 4.62,17.92 4.56,17.9 4.5,17.87 4.45,17.84 4.39,17.8 4.34,17.76 4.29,17.71 L 4.29,17.71 0.29,13.71 C -0.1,13.32 -0.1,12.68 0.29,12.29 0.68,11.9 1.32,11.9 1.71,12.29 L 4,14.59 4,1 C 4,0.45 4.45,-0 5,-0 5.55,-0 6,0.45 6,1 L 6,14.59 8.29,12.29 C 8.68,11.9 9.32,11.9 9.71,12.29 Z M 9.71,12.29"
      />
    </svg>
  </S.Container>
)

export default memo(ScrollIndicator)
