import styled from 'styled-components'
import Dimensions from 'spartacus/styles/dimensions'
import Colors from 'spartacus/styles/colors'

export const Content = styled.div`
  display: flex;
  margin: auto;

  picture,
  img {
    max-width: 100%;
  }
`

export const Footer = styled.div`
  background: ${Colors.Brand.Primary3};
  padding: 60px ${Dimensions.nColumns(1)};

  span a {
    color: ${Colors.UI.Black};
  }

  @media ${Dimensions.lg} {
    padding: 90px ${Dimensions.nColumns(2)};
  }
`
