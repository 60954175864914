import styled from 'styled-components'
import Dimensions from 'spartacus/styles/dimensions'
import Colors from 'spartacus/styles/colors'

export const Content = styled.div`
  width: 100%;

  .slick-dots {
    position: relative;
    bottom: 0;
    margin-bottom: 32px;

    li {
      width: 16px;
      height: 16px;
      margin: 0 32px 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  @media ${Dimensions.md} {
    .slick-dots {
      margin-bottom: 96px;
    }
  }
`

export const Dot = styled.div`
  width: 16px;
  height: 16px;
  background: ${Colors.Brand.Primary6};
  border-radius: 100%;

  .slick-active & {
    background: ${Colors.Brand.Primary1};
  }
`
