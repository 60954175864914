import styled from 'styled-components'

export const InnerContainer = styled.div`
  position: relative;
`

export const ScoreLabel = styled.div`
  text-align: center;
  position: relative;
  top: -70px;

  > span:first-child {
    display: block;
  }
`

export const Score = styled.div`
  text-align: center;
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  font-feature-settings: 'tnum' 1;

  span {
    line-height: 1em;
  }
`

export const StartLabel = styled.div`
  text-align: center;
  position: absolute;
  bottom: -6%;
  left: 13%;
`

export const EndLabel = styled.div`
  text-align: center;
  position: absolute;
  bottom: -6%;
  right: 14%;
`
