import styled from 'styled-components'
import Dimensions from 'spartacus/styles/dimensions'
import Colors from 'spartacus/styles/colors'

export const Container = styled.div`
  background: ${Colors.Brand.Primary5};
  padding: 4vh ${Dimensions.nColumns(2)};

  @media ${Dimensions.md} {
    padding: 8vh ${Dimensions.nColumns(2)};
  }
`

export const Row = styled.div`
  flex-wrap: wrap;
  @media ${Dimensions.md} {
    display: flex;
    align-items: center;
  }
`

export const Content = styled.div`
  margin-bottom: 24px;
  flex: 1;

  button {
    display: none;
  }

  @media ${Dimensions.md} {
    margin: 0 10% 0 0;

    button {
      display: block;
    }
  }
`

export const Logos = styled.div`
  display: flex;
`

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  picture,
  img {
    width: 80%;
    display: block;
    margin: 0 auto;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: 100%;
  }
`

export const Slide = styled.div`
  padding: 24px 0 72px;
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;

  img {
    display: inline-block;
    max-width: 80%;
  }

  picture {
    margin: 0 auto;
  }
`

export const PromiseLink = styled.div`
  display: block;

  a {
    text-decoration: underline;
    color: ${Colors.Type.Primary};
    text-align: center;
    display: block;
  }

  @media ${Dimensions.md} {
    display: none;
  }
`
