import * as React from 'react'
import { useRouter } from 'next/router'
import useStores from 'spartacus/hooks/useStores'
import Button from 'spartacus/components/Button'
import Image from 'spartacus/components/Image'
import Typography from 'spartacus/components/Typography'
import Colors from 'spartacus/styles/colors'
import Illustration from './assets/illustration.png?resize'
import IllustrationWebP from './assets/illustration.png?webp'

import * as S from './style'

const LandingCTABelt = (): JSX.Element => {
  const { analyticsStore } = useStores()
  const router = useRouter()

  return (
    <S.Container>
      <S.Content>
        <Typography color={Colors.UI.White} element="h2" md="TS6 - Strong" xs="TS5 - Strong">
          Secure your personal data.
        </Typography>
        <Typography color={Colors.UI.White} element="p" md="TS3 - Regular" xs="TS2 - Regular">
          You’re one step closer to being protected!
          <br />
          Sign up today.
        </Typography>
        <Button
          variant="primary-light"
          onClick={(): void => {
            analyticsStore.event({
              category: 'cta',
              action: 'bottom',
              label: 'Sign Up',
            })

            router.push('/offer')
          }}
        >
          Get Started
        </Button>

        <S.Disclaimers>
          <div>
            †The Identity Theft Insurance is underwritten and administered by American Bankers
            Insurance Company of Florida, an Assurant company under group or blanket policy(ies).
            The description herein is a summary and intended for informational purposes only and
            does not include all terms, conditions and exclusions of the policies described. Please
            refer to the actual policies for terms, conditions, and exclusions of coverage. Coverage
            may not be available in all jurisdictions. Review the Summary of Benefits.
          </div>
          <div>
            *Calculated on the VantageScore 3.0 model. Your VantageScore 3.0 from Experian indicates
            your credit risk level and is not used by all lenders, so don’t be surprised if your
            lender uses a score that’s different from your VantageScore 3.0. Click here to learn
            more about this.
          </div>
        </S.Disclaimers>
      </S.Content>
      <S.ImageContainer>
        <Image
          src={Illustration}
          webp={IllustrationWebP}
          type="image/png"
          alt="Secure your personal data"
          loading="lazy"
        />
      </S.ImageContainer>
    </S.Container>
  )
}

export default LandingCTABelt
