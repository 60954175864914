import Dimensions from 'spartacus/styles/dimensions'
import styled from 'styled-components'

export const Container = styled.div`
  overflow: hidden;
  position: relative;
  top: -80px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 0%;
  }

  @media ${Dimensions.xs} {
    img {
      object-fit: cover;
      object-position: 100% 60%;
    }
  }

  @media ${Dimensions.lg} {
    img {
      object-fit: cover;
      object-position: 30% 50%;
    }
  }

  @media ${Dimensions.xl} {
    img {
      object-fit: cover;
      object-position: 0% 65%;
    }
  }
`

export const InnerContainer = styled.div`
  padding: 80px ${Dimensions.nColumns(2)} 0 ${Dimensions.nColumns(2)};

  @media ${Dimensions.md} {
    padding: 8vh ${Dimensions.nColumns(2)} 0 ${Dimensions.nColumns(2)};
  }
`

export const HeroImageContainer = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  picture:nth-child(1) {
    @media ${Dimensions.xs} {
      display: none;
    }
    @media ${Dimensions.md} {
      display: inline;
    }
  }

  picture:nth-child(2) {
    @media ${Dimensions.xs} {
      display: inline;
    }
    @media ${Dimensions.md} {
      display: none;
    }
  }
`

export const Content = styled.div`
  padding: 32px ${Dimensions.nColumns(2)} 24px ${Dimensions.nColumns(2)};

  position: relative;
  z-index: 1;

  @media ${Dimensions.md} {
    max-width: 88%;
    padding: 40px ${Dimensions.nColumns(2)} 24px ${Dimensions.nColumns(2)};
  }

  @media ${Dimensions.xl} {
    max-width: 58%;
  }
`

export const LogoMarkContainer = styled.div`
  svg {
    height: 48px;
  }

  @media ${Dimensions.lg} {
    svg {
      height: 64px;
    }
  }
`

export const BannerContainer = styled.div`
  position: relative;
  top: 80px;
`

export const ScrollIndicatorContainer = styled.div`
  margin-bottom: 32px;
  padding: 0 ${Dimensions.nColumns(2)} 0 ${Dimensions.nColumns(2)};
  position: relative;

  @media ${Dimensions.lg} {
    margin-bottom: 5px;
  }
`

export const ImageContainer = styled.div`
  img {
    width: 120%;
    position: relative;
    left: -10%;
  }

  @media ${Dimensions.lg} {
    position: absolute;
    top: 104px;
    left: 51%;
    width: 60%;
    max-width: 840px;

    img {
      width: 100%;
      left: 0;
    }
  }
`
