import styled from 'styled-components'
import Dimensions from 'spartacus/styles/dimensions'
import Colors from 'spartacus/styles/colors'

export const Container = styled.div`
  background: ${Colors.Brand.Primary1};
  display: flex;
  flex-direction: column-reverse;
  padding: 4vh ${Dimensions.nColumns(2)};

  @media ${Dimensions.md} {
    padding: 8vh ${Dimensions.nColumns(2)};
    flex-direction: row;
  }
`

export const ImageContainer = styled.div`
  max-width: 50px;
  margin: auto;
  padding: 0 0 ${Dimensions.nColumns(2)} 0;

  picture img {
    max-width: 100%;
  }

  @media ${Dimensions.md} {
    flex: 1;
    margin: -10px auto 0;
    max-width: 300px;
    padding-left: ${Dimensions.nColumns(1)};
  }
`

export const Content = styled.div`
  flex: 2;
  text-align: center;

  button {
    margin: 0 auto;
  }

  @media ${Dimensions.md} {
    text-align: left;
  }
`

export const Disclaimers = styled.div`
  display: flex;
  text-align: left;
  margin-top: 120px;
  font-size: 10px;
  line-height: 18px;
  color: ${Colors.UI.Background};

  @media ${Dimensions.xs} {
    flex-wrap: wrap;
  }

  > div {
    width: 100%;
    padding: 0 0 20px;

    @media ${Dimensions.md} {
      padding: 0 40px 0 0;
      width: 50%;
    }
  }
`
