import styled from 'styled-components'
import Dimensions from 'spartacus/styles/dimensions'
import Colors from 'spartacus/styles/colors'

export const Container = styled.div`
  background: ${Colors.Brand.Primary5};
  padding: 0 ${Dimensions.nColumns(2)};
`

export const Content = styled.div`
  .slick-dots {
    position: relative;
    bottom: 0;
    margin-bottom: 32px;

    li {
      width: 16px;
      height: 16px;
      margin: 0 32px 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  @media ${Dimensions.md} {
    .slick-dots {
      margin-bottom: 96px;
    }
  }
`

export const Slide = styled.div`
  padding: 24px 0 72px;

  h4 {
    font-weight: 600;
    font-size: 14px;
    color: ${Colors.Brand.Primary1};
    letter-spacing: 0.88px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 24px;

    svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      position: relative;
      top: 3px;
    }
  }

  @media ${Dimensions.md} {
    padding: 128px 0 96px;

    h4 {
      margin-bottom: 16px;
    }
  }
`

export const SlideContent = styled.div`
  @media ${Dimensions.md} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const SlideImage = styled.div`
  max-width: 236px; /* match comp */
  margin: 0 auto 24px;

  img {
    width: 100%;
  }

  @media ${Dimensions.md} {
    flex: 1;
    max-width: 100%;
    margin: 0 10% 0 0;
  }
`

export const SlideText = styled.div`
  h3 {
    margin: 0 0 24px;
  }

  @media ${Dimensions.md} {
    flex: 1;
  }
`
