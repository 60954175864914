import { arc } from 'd3-shape'
import React from 'react'
import Typography from 'spartacus/components/Typography'
import Colors, { Color } from 'spartacus/styles/colors'
import { PrivacySeverity } from 'spartacus/types/__generated_graph'
import { getSeverityByPrivacyScore } from 'spartacus/utils'
import * as S from './style'

export interface Props {
  privacyScore: number
  /**
   * Unique id per-page.
   */
  svgID: string
  textColor?: Color
}

const INNER_RADIUS = 0.8
const OUTER_RADIUS = 0.95

const getSeverityLabelColor = (severity: PrivacySeverity): Color => {
  if (severity === PrivacySeverity.NeedsWork) return Colors.Brand.Secondary5
  if (severity === PrivacySeverity.Decent) return Colors.Brand.Secondary7

  return Colors.Brand.Primary1
}

const getSeverityPrivacyScoreColor = (severity: PrivacySeverity): Color => {
  if (severity === PrivacySeverity.NeedsWork) return Colors.Brand.Secondary5
  if (severity === PrivacySeverity.Decent) return Colors.Brand.Secondary1

  return Colors.Brand.Primary1
}

const backgroundArc = arc<null>()
  .innerRadius(INNER_RADIUS)
  .outerRadius(OUTER_RADIUS)
  .startAngle(-Math.PI / 1.5)
  .endAngle(Math.PI / 1.5)
  .cornerRadius(1)(null) as string

const PrivacyScoreChart = ({
  privacyScore,
  svgID,
  textColor = Colors.Type.Primary,
}: Props): JSX.Element => {
  const severity = getSeverityByPrivacyScore(privacyScore)
  const severityLabelColor = getSeverityLabelColor(severity)
  const severityRiskScoreColor = getSeverityPrivacyScoreColor(severity)
  const severityValue = severity === PrivacySeverity.NeedsWork ? 'Needs Work' : severity
  const scoreLabel = severity === PrivacySeverity.NeedsWork ? 'Your score' : 'Your score is'

  const riskScoreArc = arc<null>()
    .innerRadius(INNER_RADIUS)
    .outerRadius(OUTER_RADIUS)
    .startAngle(-Math.PI / 1.5)
    .endAngle(((4 * Math.PI) / 3) * (privacyScore / 100) - (2 * Math.PI) / 3)
    .cornerRadius(1)(null) as string

  return (
    <>
      <S.InnerContainer>
        <svg width="100%" viewBox="-1 -1 2 1.5">
          <path d={backgroundArc} fill={Colors.Brand.Primary5} />
          <path d={riskScoreArc} fill={severityRiskScoreColor} />
        </svg>
        <S.StartLabel>
          <Typography color={Colors.UI.LunarRock} kind="TS1 - Regular">
            0
          </Typography>
        </S.StartLabel>
        <S.Score>
          <Typography color={textColor} kind="TS6 - Strong">
            {privacyScore}
          </Typography>
        </S.Score>
        <S.EndLabel>
          <Typography color={Colors.UI.LunarRock} kind="TS1 - Regular">
            100
          </Typography>
        </S.EndLabel>
      </S.InnerContainer>
      <S.ScoreLabel>
        <Typography element="p" color={textColor} kind="TS1 - Regular">
          {scoreLabel}{' '}
          <Typography kind="TS1 - Strong" color={severityLabelColor}>
            {severityValue}
          </Typography>
        </Typography>
        {svgID !== 'dashboard-get-started' && (
          <Typography element="p" color={Colors.Type.Secondary} kind="TSL - Light Beta">
            Updated just now
          </Typography>
        )}
      </S.ScoreLabel>
    </>
  )
}

export default PrivacyScoreChart
