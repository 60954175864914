import { useRouter } from 'next/router'
import useStores from 'spartacus/hooks/useStores'
import Typography from 'spartacus/components/Typography'
import Button from 'spartacus/components/Button'
import Image from 'spartacus/components/Image'
import SearchIcon from './assets/search-icon.png?resize'
import SearchIconWebP from './assets/search-icon.png?webp'
import DeleteIcon from './assets/delete-icon.png?resize'
import DeleteIconWebP from './assets/delete-icon.png?webp'
import ShieldIcon from './assets/shield-icon.png?resize'
import ShieldIconWebP from './assets/shield-icon.png?webp'
import * as S from './style'

export const LandingHowItWorks = (): JSX.Element => {
  const { analyticsStore } = useStores()
  const router = useRouter()

  return (
    <S.Container>
      <S.CopyContainer>
        <Typography element="h2" xs="TS4 - Strong" lg="TS5 - Strong">
          How it Works
        </Typography>
        <Typography element="p" xs="TS2 - Regular" lg="TS3 - Regular">
          We delete and protect your data in three easy steps.
        </Typography>
      </S.CopyContainer>
      <S.List>
        <li>
          <S.IconContainer>
            <Image src={SearchIcon} webp={SearchIconWebP} type="image/png" alt="" />
          </S.IconContainer>
          <Typography xs="TS2 - Strong" lg="TS3 - Strong" element="h3">
            Scan
          </Typography>
          <Typography xs="TS1 - Regular" lg="TS2 - Regular" element="p">
            We continuously scan your data to assess your online privacy risk. Think of us as your
            privacy detective.
          </Typography>
        </li>

        <li>
          <S.IconContainer>
            <Image src={DeleteIcon} webp={DeleteIconWebP} type="image/png" alt="" />
          </S.IconContainer>
          <Typography xs="TS2 - Strong" lg="TS3 - Strong" element="h3">
            Delete
          </Typography>
          <Typography xs="TS1 - Regular" lg="TS2 - Regular" element="p">
            We automate the continuous removal of your personal data from over 100 third party data
            broker websites and notify you along the way.
          </Typography>
        </li>

        <li>
          <S.IconContainer>
            <Image src={ShieldIcon} webp={ShieldIconWebP} type="image/png" alt="" />
          </S.IconContainer>
          <Typography xs="TS2 - Strong" lg="TS3 - Strong" element="h3">
            Protect
          </Typography>
          <Typography xs="TS1 - Regular" lg="TS2 - Regular" element="p">
            We continuously monitor your identity, passwords, and cover you up to $1 million if
            identity theft occurs.*
          </Typography>
        </li>
      </S.List>
      <S.CTAContainer>
        <Button
          onClick={(): void => {
            analyticsStore.event({
              category: 'cta',
              action: 'bottom',
              label: 'Sign Up',
            })

            router.push('/offer')
          }}
        >
          Get Started
        </Button>
      </S.CTAContainer>
    </S.Container>
  )
}

export default LandingHowItWorks
