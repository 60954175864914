import React from 'react'
import Layout from 'spartacus/components/Layout'
import SEO from 'spartacus/components/SEO'
import TestimonialCarousel from 'spartacus/components/TestimonialCarousel'
import GetStarted from './sections/GetStarted'
import Instagram from './sections/Instagram'
import LandingCarousel from './sections/LandingCarousel'
import LandingCTABelt from './sections/LandingCTABelt'
import LandingHero from './sections/LandingHero'
import LandingHowItWorks from './sections/LandingHowItWorks'
import LandingInvestors from './sections/LandingInvestors'
import LandingWhyTrustUs from './sections/LandingWhyTrustUs'

const LandingView = (): JSX.Element => (
  <Layout>
    <SEO
      title="Protect your internet privacy"
      description="Is your internet privacy at risk? Get your FREE Risk Score and take back control with identity theft protection from data breaches"
      url="/"
    />
    <>
      <LandingHero />
      <LandingHowItWorks />
      <GetStarted />
      <LandingCarousel />
      <TestimonialCarousel />
      <LandingWhyTrustUs />
      <LandingInvestors />
      <LandingCTABelt />
      <Instagram />
    </>
  </Layout>
)

export default LandingView
