import Colors from 'spartacus/styles/colors'
import Dimensions from 'spartacus/styles/dimensions'
import styled from 'styled-components'

export const Container = styled.div`
  background: ${Colors.Brand.Primary4};
  padding: 4vh ${Dimensions.nColumns(2)};
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  button {
    width: 100%;
  }

  h2 {
    order: -2;
    text-align: center;

    @media ${Dimensions.lg} {
      order: unset;
      text-align: unset;
    }
  }

  @media ${Dimensions.md} {
    padding: 64px 0;

    button {
      width: auto;
    }
  }

  @media ${Dimensions.lg} {
    justify-content: space-between;
  }
`

export const Description = styled.div`
  display: none;

  @media ${Dimensions.md} {
    display: inherit;
  }
`

export const Form = styled.div`
  @media ${Dimensions.lg} {
    max-width: 60%;
  }
`

export const Chart = styled.div`
  margin: 0 32px 0 auto;
  order: -1;
  margin: 0 0 32px;

  @media ${Dimensions.lg} {
    max-width: 40%;
    order: unset;
    text-align: unset;
  }
`
